import { Link } from "gatsby"
import React from "react"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <div class="content has-text-centered">
      <Link to="/">Home</Link> | <Link to="/about/privacy">Privacy</Link>
      <br />
      <strong>© {new Date().getFullYear()} - Writing Ones and Zeros</strong>
    </div>
  </footer>
)

export default Footer
