import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import brandLogo from "../images/stef64.png"

const Header = ({ siteTitle }) => (
  <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
      <Link to="/">
        <figure className="image is-64x64 is-padded">
          <img
            src={brandLogo}
            alt="STEF - Simple Timeline Estimate Forecaster"
          ></img>
        </figure>

      </Link>
      <div className="navbar-item">
        <h1 class="subtitle">
          Simple Timeline Estimate Forecaster
        </h1>
      </div>
    </div>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
